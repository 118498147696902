import { MobileViewService } from './services/mobileView.service';
import { Component, OnInit } from '@angular/core';

import {  Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {


  valueReceived(value: string) {
    console.log("value received" + value);
  }

  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Search',
      url: '/search',
      icon: 'search'
    },
    /*
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'stats-chart'
    }
    */
  ];
  public labels = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar, public mobileViewService: MobileViewService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  mobileChecked: boolean = true;

  onMobileToggle(event) {
    if (this.mobileChecked === true) {
      document.querySelector("body").style.cssText = `width: 375px;
      height: 667px;
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid #4b4b4b;`;
      this.mobileChecked = false;
    } else {
      document.querySelector("body").style.cssText = "";
      this.mobileChecked = true;
    }
    this.mobileViewService.publish('mobileView', {

  });

    
  }


}
